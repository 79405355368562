import React, { useEffect, useRef, useContext, useState, FormEvent, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { checkUserExists, checkUserExistsBySub } from '../../AuthService/cognitoAdmin';
import { signUp, signIn, getCurrentUser } from '../../AuthService/AuthService';
import { AuthContext } from './AuthContext';

interface PendingUserData {
  sub: string;
  name: string;
}

const AppleCallback: React.FC = () => {
  const navigate = useNavigate();
  const { setUser, setSession } = useContext(AuthContext);
  const appleDefaultPassword = "AppleSignInPassword1!"; // Apple 전용 기본 비밀번호

  // 중복 실행 방지
  const effectRan = useRef(false);

  // 모달 관련 상태
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [manualEmail, setManualEmail] = useState("");
  const [pendingUserData, setPendingUserData] = useState<PendingUserData | null>(null);

  // 사용자 처리 함수 (회원가입/로그인)
  const processUser = useCallback(
    async (email: string, sub: string, name: string) => {
      try {
        // sub에 애플 접두어 추가 (Lambda 트리거에서 식별용)
        const appleSub = `apple_${sub}`;
        
        // sub로 이미 가입된 사용자가 있는지 확인 (가입된 경우 해당 이메일 반환)
        const subResult = await checkUserExistsBySub(appleSub);
        // 동일한 이메일로 가입된 사용자가 있는지 확인
        const existsByEmail = await checkUserExists(email);
        console.log(email, existsByEmail, subResult);

        if (subResult !== false) {
          // 이미 sub로 가입된 사용자가 있다면, 해당 이메일로 자동 로그인 시도
          try {
            const signInResult = await signIn(subResult, appleDefaultPassword);
            const currentUser = getCurrentUser();
            setUser(currentUser);
            setSession(signInResult);
            navigate('/');
          } catch (loginError) {
            console.error('자동 로그인 실패:', loginError);
            alert('자동 로그인이 실패했습니다. 기존 가입한 경로로 로그인해주세요.');
            navigate('/signin');
          }
        } else {
          if (existsByEmail) {
            // 동일 이메일로 가입된 사용자가 이미 있다면 에러 처리
            alert('이미 동일한 이메일로 가입된 회원이 있습니다.');
            navigate('/signin');
          } else {
            try {
              // 신규 회원 가입 - Lambda 트리거에 의해 자동 확인됨
              // custom:linesub 속성에 apple 접두어가 있는 sub 저장
              // 마지막 매개변수(sub)를 custom:linesub 속성으로 저장하도록 AuthService가 구현되어 있어야 함
              await signUp(email, appleDefaultPassword, name, '', '', appleSub);
              console.log('신규 회원가입 처리 완료 (apple 접두어 추가됨)');
              
              try {
                // 회원가입 완료 후 바로 로그인 시도 (Lambda 트리거로 자동 확인되었으므로 바로 로그인 가능)
                console.log('자동 로그인 시도');
                const signInResult = await signIn(email, appleDefaultPassword);
                const currentUser = getCurrentUser();
                setUser(currentUser);
                setSession(signInResult);
                
                // 로그인 성공 시 홈 화면으로 이동
                navigate('/');
              } catch (loginError) {
                console.error('자동 로그인 실패:', loginError);
                // 자동 로그인 실패 시 확인 페이지로 이동 (폴백 메커니즘)
                alert('자동 로그인에 실패했습니다. 이메일 확인이 필요할 수 있습니다.');
                navigate('/confirm-signup', { state: { email } });
              }
            } catch (signupError) {
              console.error('회원가입 실패:', signupError);
              alert(`회원가입 처리 중 오류가 발생했습니다: ${signupError}`);
              navigate('/signin');
            }
          }
        }
      } catch (error) {
        console.error('사용자 처리 중 오류 발생:', error);
        alert(`사용자 처리 중 오류 발생: ${error}`);
        navigate('/signin');
      }
    },
    [navigate, setUser, setSession, appleDefaultPassword]
  );

  // 모달 제출 핸들러 (수동 이메일 입력)
  const handleEmailSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (!manualEmail.trim() || !pendingUserData) return;
    await processUser(manualEmail.trim(), pendingUserData.sub, pendingUserData.name);
  };

  useEffect(() => {
    if (effectRan.current) return;
    effectRan.current = true;

    const handleAppleLogin = async () => {
      // response_mode가 fragment인 경우 URL 해시에서 파라미터 추출
      const hash = window.location.hash;
      const params = new URLSearchParams(hash.slice(1)); // '#' 제거 후 파싱
      const idToken = params.get('id_token');

      if (!idToken) {
        alert('Apple ID 토큰을 찾을 수 없습니다.');
        navigate('/signin');
        return;
      }

      // id_token은 JWT 형식이므로 디코딩하여 사용자 정보를 추출
      let userInfo;
      try {
        const tokenParts = idToken.split('.');
        if (tokenParts.length < 2) {
          throw new Error('유효하지 않은 ID 토큰 형식입니다.');
        }
        const payload = tokenParts[1];
        // Base64Url -> Base64 변환
        const base64 = payload.replace(/-/g, '+').replace(/_/g, '/');
        const padded = base64.padEnd(base64.length + (4 - (base64.length % 4)) % 4, '=');
        const jsonPayload = decodeURIComponent(
          atob(padded)
            .split('')
            .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
            .join('')
        );
        userInfo = JSON.parse(jsonPayload);
      } catch (error) {
        console.error('ID 토큰 디코딩 실패:', error);
        alert('ID 토큰을 디코딩하는데 실패했습니다.');
        navigate('/signin');
        return;
      }

      // Apple id_token의 payload에서 sub, 이메일, 이름 추출
      console.log(userInfo)
      const sub = userInfo.sub;
      const payloadEmail = userInfo.email; // 이메일 정보 (있을 수도, 없을 수도 있음)
      const name = userInfo.name || '';

      if (!sub) {
        alert('Apple 계정에 사용자 식별자(sub) 정보가 없습니다.');
        navigate('/signin');
        return;
      }

      // 이메일 정보가 없으면, 먼저 sub로 등록된 사용자가 있는지 확인
      // 여기서도 sub에 apple 접두어를 추가하여 조회
      if (!payloadEmail) {
        const appleSub = `apple_${sub}`;
        const subResult = await checkUserExistsBySub(appleSub);
        if (subResult !== false) {
          // 이미 가입된 사용자의 이메일이 반환되면 그걸로 자동 로그인
          await processUser(subResult, sub, name);
          return;
        } else {
          // 이메일 정보가 없고, 가입된 이메일도 없으면 모달을 띄워 사용자에게 직접 입력받음
          // setPendingUserData({ sub, name });
          // setShowEmailModal(true);
          return;
        }
      }

      // 이메일 정보가 payload에 존재하면 바로 회원가입 여부 체크 및 로그인/회원가입 처리 진행
      await processUser(payloadEmail, sub, name);
    };

    handleAppleLogin();
  }, [navigate, processUser]);

  return (
    <div>
      {/* Apple 로그인 처리 중 별도 UI 처리(필요 시) */}
      {showEmailModal && (
        <div style={modalOverlayStyle}>
          <div style={modalStyle}>
            <h2>이메일 입력</h2>
            <p>Apple 계정에 이메일 정보가 없어 직접 입력해 주세요.</p>
            <form onSubmit={handleEmailSubmit}>
              <input
                type="email"
                placeholder="이메일 입력"
                value={manualEmail}
                onChange={(e) => setManualEmail(e.target.value)}
                required
                style={{ padding: '8px', width: '100%', marginBottom: '12px' }}
              />
              <button type="submit" style={buttonStyle}>확인</button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

// 모달 스타일 (원하는 대로 수정 가능)
const modalOverlayStyle: React.CSSProperties = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0,0,0,0.5)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 1000,
};

const modalStyle: React.CSSProperties = {
  backgroundColor: '#fff',
  padding: '24px',
  borderRadius: '8px',
  width: '90%',
  maxWidth: '400px',
  boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
};

const buttonStyle: React.CSSProperties = {
  padding: '10px 20px',
  backgroundColor: '#007BFF',
  color: '#fff',
  border: 'none',
  borderRadius: '4px',
  cursor: 'pointer',
};

export default AppleCallback;